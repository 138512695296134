@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&family=Open+Sans&family=Roboto&display=swap");

main {
  margin: auto;
}

* {
  /* font-family: "Josefin Sans", sans-serif; */
  font-family: 'Open Sans', sans-serif;

  /* font-family: "Roboto", sans-serif; */
  scroll-behavior: smooth;
}
